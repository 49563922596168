import React, { useEffect, useState, ChangeEvent } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Chip,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox, // Import Checkbox
  Link, // Import Link for the terms URL
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useFormik, FormikProvider, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppContext } from "./AppProvider";
import { toast } from "react-toastify";
import { DeliverySettingsDialog } from "./dialogs/DeliverySettingsDialog";
import { BusinessSettings, Team, ProductCategory, ProductsTextsUtil, IDUtil, I18nUtil, Log, CallerType, AppError } from "base.f6st.com";
import { AddressSelection, StandardSwitch, StandardSlider, SecondaryButton, StandardButton, SecurityCodeClient } from "common.f6st.com";

type BusinessSettingsFormProps = {
  onSubmitCallback: (data: BusinessSettings, verificationCode?: string) => void;
  useCase: "REGISTRATION" | "FULLSETTINGS";
  existingSettings?: BusinessSettings;
  userLoginCode?: string;
  codeId?: string;
};

// Extend the validation schema to include 'terms' for REGISTRATION
// Update the validation schema to include 'address'
const createValidationSchema = (useCase: "REGISTRATION" | "FULLSETTINGS") => {
  if (useCase === "REGISTRATION") {
    return Yup.object().shape({
      businessName: Yup.string().required("Name of your business is required."),
      languageCodePrimary: Yup.string().required("Business Language is required."),
      countryCode: Yup.string().required("Country is required."),
      vatID: Yup.string().required("VAT ID is required."),
      phoneNumber: Yup.string().required("Phone Number is required."),
      defaultVAT: Yup.number()
        .required("Default VAT percentage is required.")
        .min(0, "VAT percentage must be greater than or equal to 0.")
        .max(100, "VAT percentage cannot be greater than 100."),
      terms: Yup.boolean()
        .oneOf([true], "You must accept the Terms & Privacy policy.")
        .required("You must accept the Terms & Privacy policy."),
      address: Yup.object().shape({
        placeId: Yup.string().required("No proper address is set."),
        city: Yup.string().required("City is required."),
        street: Yup.string().required("Street is required."),
        zip: Yup.string().required("Zip Code is required."),
      }),
    });
  } else {
    return Yup.object().shape({
      businessName: Yup.string().required("Name of your business is required."),
      languageCodePrimary: Yup.string().required("Business Language is required."),
      countryCode: Yup.string().required("Country is required."),
      vatID: Yup.string().required("VAT ID is required."),
      phoneNumber: Yup.string().required("Phone Number is required."),
      defaultVAT: Yup.number()
        .required("Default VAT percentage is required.")
        .min(0, "VAT percentage must be greater than or equal to 0.")
        .max(100, "VAT percentage cannot be greater than 100."),
      teams: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Team name is required"),
          categoryIds: Yup.array().min(1, "At least one category is required"),
        })
      ),
      address: Yup.object().shape({
        placeId: Yup.string().required("No proper address is set."),
        city: Yup.string().required("City is required."),
        street: Yup.string().required("Street is required."),
        zip: Yup.string().required("Zip Code is required."),
      }),
    });
  }
};

export const BusinessSettingsForm = ({
  onSubmitCallback,
  useCase,
  existingSettings,
  codeId,
}: BusinessSettingsFormProps) => {
  const { t } = useTranslation();
  const { loadCustomer } = useAppContext();
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState("");
  const [teams, setTeams] = useState<Team[]>(existingSettings?.teams || []);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [businessLanguages, setBusinessLanguages] = useState<string[]>([]);
  const customer = useAppContext().getCustomer();
  const [texts, setTexts] = useState<ProductsTextsUtil | undefined>(undefined);
  const [deliverySettingsDialogOpen, setDeliverySettingsDialogOpen] = useState(false);
  const [hasFetchedProducts, setHasFetchedProducts] = useState(false);
  const [deliveryEnabled, setDeliveryEnabled] = useState(existingSettings?.delivery !== undefined);
  // Removed addressError state
  // const [addressError, setAddressError] = useState<string | undefined>(undefined);

  // Define a new type for form values that includes 'terms' when registering
  type FormValues = BusinessSettings & {
    terms?: boolean; // 'terms' is only required for REGISTRATION
  };

  const formik = useFormik<FormValues>({
    initialValues: existingSettings || {
      businessName: "",
      languageCodePrimary: "",
      countryCode: "",
      address: {
        placeId: "",
        zip: "",
        city: "",
        street: "",
        lat: 0,
        lng: 0,
      },
      connectedAccountId: "",
      testMode: false,
      teams: undefined,
      serviceFeePercentage: undefined,
      discountPercentage: undefined,
      tipsPercentage: undefined,
      payInPerson: false,
      orderEmailNotification: false,
      productRecommendation: false,
      defaultVAT: 0,
      vatID: "",
      phoneNumber: "",
      delivery: undefined,
      ...(useCase === "REGISTRATION" ? { terms: false } : {}), // Added 'terms' to initialValues for REGISTRATION
    },
    validationSchema: createValidationSchema(useCase),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (data) => {
      const validTeams = teams.filter((team) => team.name.trim() && team.categoryIds.length > 0);

      if (validTeams.length === 0) {
        data.teams = undefined;
      } else {
        data.teams = validTeams;
      }

      if (useCase === "REGISTRATION") {
        if (!data.terms) { // Ensure 'terms' is accepted
          formik.setFieldError("terms", "You must accept the Terms & Privacy policy.");
          return;
        }

        if (!verificationCode) {
          setVerificationCodeError("Verification code is required.");
          return;
        }
        if (!codeId) throw new AppError("Missing mail validation code id");
        const validationResult = await SecurityCodeClient.validateSecurityCode(CallerType.ADMIN, codeId, verificationCode);

        if (!validationResult.isValid) {
          setVerificationCodeError("Invalid verification code.");
          return;
        }
      }

      // Exclude 'terms' from the data before submitting
      const { terms, ...businessData } = data;
      onSubmitCallback(businessData, verificationCode);
    },
  });

  useEffect(() => {
    if (customer) {
      if (!formik.values.countryCode) {
        const countryCode = customer.businessSettings.countryCode;
        formik.setFieldValue("countryCode", countryCode);
        const countryData = I18nUtil.getSupportedCountry(countryCode);
        setBusinessLanguages(countryData.languageCodes);

        if (useCase === "REGISTRATION") {
          formik.setFieldValue("languageCodePrimary", countryData.languageCodes[0]);
          formik.setFieldValue("defaultVAT", countryData.vatDefault);
        }
      }

      if (existingSettings?.delivery) {
        setDeliveryEnabled(true);
      }
    }
  }, [customer, existingSettings, formik, useCase]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (customer && !hasFetchedProducts) {
        const customerNew = await loadCustomer(customer.id, customer.businessSettings.languageCodePrimary);
        const texts = new ProductsTextsUtil(customerNew);
        const products = customerNew.products;
        const copiedCategories = products.categories.map((category: ProductCategory) => ({
          ...category,
        }));
        setCategories(copiedCategories);
        setTexts(texts);
        setHasFetchedProducts(true);
      }
    };

    if (useCase === "FULLSETTINGS") {
      fetchProducts();
    }
  }, [customer, hasFetchedProducts, loadCustomer, useCase]);;

  const handleButtonClick = async () => {
    const errors = await formik.validateForm();

    if (Object.keys(errors).length > 0) {
      formik.setTouched(
        Object.keys(formik.values).reduce((acc: Record<string, boolean>, key: string) => {
          acc[key] = true;
          return acc;
        }, {})
      );
      toast.error("Please fix all validation errors");
    } else {
      await formik.submitForm();
    }
  };

  const handleDeliveryCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeliveryEnabled(event.target.checked);
    if (!event.target.checked) {
      formik.setFieldValue("delivery", undefined);
    }
  };

  const handleAddressSelection = (field: string, value: any) => {
    formik.setFieldValue('address', value);
    // Removed setAddressError call
  };

  // Removed handleAddressValidationError function
  // const handleAddressValidationError = (error: string) => {
  //   setAddressError(error);
  // };

  if (useCase === "FULLSETTINGS" && !texts) {
    Log.debug("No product texts are loaded yet");
    return null;
  }

  return (
    <FormikProvider value={formik}>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} component="form" onSubmit={formik.handleSubmit}>
        <FormControl fullWidth required error={Boolean(formik.touched.countryCode && formik.errors.countryCode)}>
          <InputLabel>Country of your business</InputLabel>
          <Field
            name="countryCode"
            as={Select}
            label="Country of your business"
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              formik.handleChange(e);
              formik.setFieldValue("countryCode", e.target.value);
              formik.setFieldValue("defaultVAT", I18nUtil.getSupportedCountry(e.target.value as string).vatDefault);
            }}
            disabled
            renderValue={(selected: string) => {
              const country = I18nUtil.getSupportedCountries().find((c) => c.countryCode === selected);
              return country ? t(`country.${country.countryCode}`) : "";
            }}
          >
            {I18nUtil.getSupportedCountries().map((country) => (
              <MenuItem value={country.countryCode} key={country.countryCode}>
                {t(`country.${country.countryCode}`)}
              </MenuItem>
            ))}
          </Field>
          <FormHelperText>{formik.touched.countryCode && formik.errors.countryCode}</FormHelperText>
        </FormControl>

        <FormControl fullWidth required error={Boolean(formik.touched.languageCodePrimary && formik.errors.languageCodePrimary)}>

          {businessLanguages.length <= 1 ? (
            <TextField
              label="Business Language"
              variant="outlined"
              value={
                useCase === "FULLSETTINGS"
                  ? t(`language.${formik.values.languageCodePrimary}`)
                  : t(`language.${businessLanguages[0]}`)
              }
              disabled
              onChange={formik.handleChange}
              name="languageCodePrimary"
              error={Boolean(formik.touched.languageCodePrimary && formik.errors.languageCodePrimary)}
              helperText={formik.touched.languageCodePrimary && formik.errors.languageCodePrimary}
              required
            />
          ) : (
            <Autocomplete
              options={businessLanguages}
              value={formik.values.languageCodePrimary}
              disableClearable
              onChange={(_, newValue: string | null) => formik.setFieldValue("languageCodePrimary", newValue || "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Business Language"
                  placeholder="Select business language"
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.touched.languageCodePrimary && formik.errors.languageCodePrimary)}
                  helperText={formik.touched.languageCodePrimary && formik.errors.languageCodePrimary}
                />
              )}
              getOptionLabel={(option: string) => t(`language.${option}`)}
              isOptionEqualToValue={(option, value) => option === value}
            />
          )}
        </FormControl>

        <TextField
          fullWidth
          label="Name of your business"
          variant="outlined"
          {...formik.getFieldProps("businessName")}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.businessName && formik.errors.businessName)}
          helperText={formik.touched.businessName && formik.errors.businessName}
          required
        />

        <TextField
          fullWidth
          label="VAT ID"
          variant="outlined"
          {...formik.getFieldProps("vatID")}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.vatID && formik.errors.vatID)}
          helperText={formik.touched.vatID && formik.errors.vatID}
          required
        />

        <TextField
          fullWidth
          label="Default VAT Percentage"
          variant="outlined"
          type="number"
          {...formik.getFieldProps("defaultVAT")}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.defaultVAT && formik.errors.defaultVAT)}
          helperText={formik.touched.defaultVAT && formik.errors.defaultVAT}
          required
        />

        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          {...formik.getFieldProps("phoneNumber")}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          required
        />

        {formik.values.countryCode && (
          <>
            <AddressSelection
              countryCode={formik.values.countryCode}
              label="Business Address"
              defaultPlaceId={existingSettings?.address.placeId}
              fieldName={"address"}
              setFieldValue={handleAddressSelection}
            />
            {formik.touched.address && formik.errors.address && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {Object.values(formik.errors.address)[0]}
              </Typography>
            )}
          </>
        )}

        {useCase === "REGISTRATION" && (
          <>
            <TextField
              label="Verification code we sent to your email address"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
                setVerificationCodeError("");
              }}
              required
              error={Boolean(verificationCodeError)}
              helperText={verificationCodeError}
            />

            <FormControl
              required
              error={Boolean(formik.touched.terms && formik.errors.terms)}
              component="fieldset"
            >
              <FormControlLabel
                control={
                  <Field
                    name="terms"
                    type="checkbox"
                    as={Checkbox}
                    color="primary"
                    checked={formik.values.terms || false}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    I acknowledge F6ST{' '}
                    <Link
                      href="https://f6st.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      Terms & Privacy
                    </Link>{' policy.'}
                    <br />1% fee to F6ST, plus  {' '}
                    <Link
                      href="https://stripe.com/en-ch/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                    >
                      Stripe fees
                    </Link>{' '}
                    will be applied to each transaction.
                  </Typography>
                }
              />
              <FormHelperText>{formik.touched.terms && formik.errors.terms}</FormHelperText>
            </FormControl>
          </>
        )}

        {useCase === "FULLSETTINGS" && (
          <>
            <FormControlLabel
              control={
                <Field name="testMode" type="checkbox" as={StandardSwitch} checked={formik.values.testMode} onChange={formik.handleChange} />
              }
              label="Test mode (no real payments)"
            />

            <FormControlLabel
              control={
                <Field
                  name="payInPerson"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.payInPerson}
                  onChange={formik.handleChange}
                />
              }
              label="Customers can also pay in person e.g. cash, card terminal"
            />

            <FormControlLabel
              control={
                <Field
                  name="tipsPercentage"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.tipsPercentage !== undefined}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      formik.setFieldValue("tipsPercentage", 5);
                    } else {
                      formik.setFieldValue("tipsPercentage", undefined);
                    }
                  }}
                />
              }
              label="Tips"
            />

            {formik.values.tipsPercentage !== undefined && (
              <StandardSlider
                unit="%"
                min={1}
                max={30}
                {...formik.getFieldProps("tipsPercentage")}
                value={formik.values.tipsPercentage || 0}
                onChange={(e, value) => formik.setFieldValue("tipsPercentage", value)}
              />
            )}

            <FormControlLabel
              control={
                <Field
                  name="discountPercentage"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.discountPercentage !== undefined}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      formik.setFieldValue("discountPercentage", 10);
                    } else {
                      formik.setFieldValue("discountPercentage", undefined);
                    }
                  }}
                />
              }
              label="Discount for returning customers (within 60 days)"
            />

            {formik.values.discountPercentage !== undefined && (
              <StandardSlider
                unit="%"
                min={1}
                max={30}
                {...formik.getFieldProps("discountPercentage")}
                value={formik.values.discountPercentage || 0}
                onChange={(e, value) => formik.setFieldValue("discountPercentage", value)}
              />
            )}

            <FormControlLabel
              control={
                <Field
                  name="serviceFeePercentage"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.serviceFeePercentage !== undefined}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      formik.setFieldValue("serviceFeePercentage", 5);
                    } else {
                      formik.setFieldValue("serviceFeePercentage", undefined);
                    }
                  }}
                />
              }
              label="Service fee"
            />

            {formik.values.serviceFeePercentage !== undefined && (
              <StandardSlider
                unit="%"
                min={1}
                max={30}
                {...formik.getFieldProps("serviceFeePercentage")}
                value={formik.values.serviceFeePercentage || 0}
                onChange={(e, value) => formik.setFieldValue("serviceFeePercentage", value)}
              />
            )}

            <Box display="flex" alignItems="center" gap={2}>
              <FormControlLabel
                control={
                  <Field
                    name="delivery"
                    type="checkbox"
                    as={StandardSwitch}
                    checked={deliveryEnabled}
                    onChange={handleDeliveryCheckboxChange}
                  />
                }
                label="Delivery (provide your customers QR codes to order to their address)"
              />
              {deliveryEnabled && (
                <SecondaryButton text="Settings" action={() => setDeliverySettingsDialogOpen(true)} />
              )}
            </Box>

            <DeliverySettingsDialog
              open={deliverySettingsDialogOpen}
              onClose={() => setDeliverySettingsDialogOpen(false)}
              businessSettings={formik.values}
            />

            <FormControlLabel
              control={
                <Field
                  name="teams"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.teams !== undefined && formik.values.teams.length > 0}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      const newTeam = { id: IDUtil.getShortId(), name: "", categoryIds: [] };
                      setTeams([newTeam]);
                      formik.setFieldValue("teams", [newTeam]);
                    } else {
                      setTeams([]);
                      formik.setFieldValue("teams", undefined);
                    }
                  }}
                />
              }
              label="Teams (route orders to different teams)"
            />

            {formik.values.teams !== undefined && formik.values.teams.length > 0 && (
              <FieldArray name="teams">
                {({ push, remove }) => (
                  <Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Team Name</TableCell>
                          <TableCell>Categories</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formik.values.teams?.map((team, index) => (
                          <TableRow key={team.id}>
                            <TableCell>
                              <TextField
                                fullWidth
                                value={team.name}
                                onChange={(e) => {
                                  const newTeams = [...teams];
                                  newTeams[index].name = e.target.value;
                                  setTeams(newTeams);
                                  formik.setFieldValue(`teams[${index}].name`, e.target.value);
                                }}
                                placeholder="Team Name"
                              />
                              <ErrorMessage name={`teams[${index}].name`} component="div" />
                            </TableCell>
                            <TableCell>
                              <Autocomplete
                                multiple
                                disableClearable
                                options={categories.map((category) => category.id)}
                                value={team.categoryIds}
                                onChange={(_, newValue: string[] | null) => {
                                  const newTeams = [...teams];
                                  newTeams[index].categoryIds = newValue || [];
                                  setTeams(newTeams);
                                  formik.setFieldValue(`teams[${index}].categoryIds`, newValue);
                                }}
                                renderTags={(value: string[], getTagProps) =>
                                  value.map((option: string, index: number) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    const categoryName = texts?.get(categories.find((category) => category.id === option)?.id) || option;
                                    return (
                                      <Chip
                                        key={key}
                                        variant="outlined"
                                        label={categoryName}
                                        {...tagProps}
                                      />
                                    );
                                  })
                                }
                                getOptionLabel={(option: string) => {
                                  const category = categories.find((category) => category.id === option);
                                  return category && texts ? texts.get(category.id) : option;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Product Categories"
                                    placeholder="Select categories"
                                  />
                                )}
                                isOptionEqualToValue={(option, value) => option === value}
                              />
                              <ErrorMessage name={`teams[${index}].categoryIds`} component="div" />
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => remove(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <SecondaryButton
                      action={() => {
                        const newTeam = { id: IDUtil.getShortId(), name: "", categoryIds: [] };
                        setTeams([...teams, newTeam]);
                        push(newTeam);
                      }}
                      style={{ marginTop: 3 }}
                      text="Add Team"
                    />
                  </Box>
                )}
              </FieldArray>
            )}

            <FormControlLabel
              control={
                <Field
                  name="orderEmailNotification"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.orderEmailNotification}
                  onChange={formik.handleChange}
                />
              }
              label={
                formik.values.teams && formik.values.teams.length > 0
                  ? "Email notification for new orders (to team members)"
                  : "Email notification for new orders (to all of your users)"
              }
            />

            <FormControlLabel
              control={
                <Field
                  name="productRecommendation"
                  type="checkbox"
                  as={StandardSwitch}
                  checked={formik.values.productRecommendation}
                  onChange={formik.handleChange}
                />
              }
              label="Enable product recommendation with Artificial Intelligence"
            />
          </>
        )}
        <StandardButton text={useCase === "REGISTRATION" ? "Next" : "Save"} action={handleButtonClick} />
      </Box>
    </FormikProvider>
  );
};
