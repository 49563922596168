import React, { useState, useEffect } from 'react';
import {
  Container,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  AlertTitle,
  Button,
  Grid,
} from "@mui/material";
import { useFormik, FormikProvider, Field, Form } from 'formik';
import * as Yup from 'yup';
import { MuiColorInput } from 'mui-color-input';
import { useAppContext } from "../components/AppProvider";
import { QRPDFUtil } from "../util/QrPdfUtil";
import { CustomAppBar } from "../components/CustomAppBar";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { OpenFile } from '@react-pdf-viewer/core';
import { StandardFonts } from "pdf-lib";
import { toast } from "react-toastify";
import { QrCodeLocationType, AppError, IDUtil, QrCode } from 'base.f6st.com';
import { StandardSwitch } from 'common.f6st.com';

const LOCAL_STORAGE_KEY = "qrCodeFormSettings";

const supportedFonts = [
  StandardFonts.Courier,
  StandardFonts.CourierBold,
  StandardFonts.CourierOblique,
  StandardFonts.CourierBoldOblique,
  StandardFonts.Helvetica,
  StandardFonts.HelveticaBold,
  StandardFonts.HelveticaOblique,
  StandardFonts.HelveticaBoldOblique,
  StandardFonts.TimesRoman,
  StandardFonts.TimesRomanBold,
  StandardFonts.TimesRomanItalic,
  StandardFonts.TimesRomanBoldItalic,
];

const validationSchema = Yup.object().shape({
  numCodes: Yup.number()
    .required('Number of QR Codes is required')
    .min(1, 'Number of QR Codes must be at least 1')
    .max(99, 'Number of QR Codes cannot exceed 99'),
  headerText: Yup.string().when('showHeaderText', {
    is: true,
    then: (schema) => schema.required('Header text is required'),
  }),
  footerText: Yup.string().when('showFooterText', {
    is: true,
    then: (schema) => schema.required('Footer text is required'),
  }),
});

const loadStoredSettings = () => {
  const storedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedSettings ? JSON.parse(storedSettings) : {};
};

export const QrCodePage: React.FC = () => {
  const customer = useAppContext().getCustomer();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      const dateStr = new Date().toISOString().split('T')[0];
      return `qr_${dateStr}.pdf`;
    },
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { Download } = getFilePluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const formik = useFormik({
    initialValues: {
      location: QrCodeLocationType.NAME,
      numCodes: 1,
      headerText: "Scan to order",
      footerText: "Scannen für Bestellung",
      showHeaderText: true,
      showFooterText: true,
      backgroundColor: "#ffffff",
      qrCodeColor: "#000000",
      headerAndFooterBackgroundColor: "#000000",
      headerAndFooterFontColor: "#ffffff",
      fontFamily: StandardFonts.Helvetica,
      ...loadStoredSettings(),
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const {
          numCodes,
          headerText,
          footerText,
          showHeaderText,
          showFooterText,
          backgroundColor,
          qrCodeColor,
          headerAndFooterBackgroundColor,
          headerAndFooterFontColor,
          fontFamily,
          location: locationType,
        } = values;

        if (!locationType) throw new AppError("No valid location");
        const id = IDUtil.getShortId();
        const qrCode: QrCode = {
          id: id,
          customerId: customer.id,
          locationType: locationType,
        };
        const { pdfBytes } = await QRPDFUtil.generatePDFs(
          headerText || "Scan to order",
          footerText || "Scannen für Bestellung",
          numCodes || 1,
          showHeaderText !== false,
          showFooterText !== false,
          backgroundColor || "#ffffff",
          qrCodeColor || "#000000",
          headerAndFooterBackgroundColor || "#000000",
          headerAndFooterFontColor || "#ffffff",
          fontFamily || StandardFonts.HelveticaBold,
          qrCode,
        );
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    formik.submitForm();
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.headerText.trim() === "") {
      formik.setFieldValue("showHeaderText", false);
    }
  }, [formik.values.headerText]);

  useEffect(() => {
    if (formik.values.footerText.trim() === "") {
      formik.setFieldValue("showFooterText", false);
    }
  }, [formik.values.footerText]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formik.values));
  }, [formik.values]);

  const handleColorChange = (field: string) => (color: string) => {
    formik.setFieldValue(field, color);
  };

  const handleFontFamilyChange = (event: SelectChangeEvent<StandardFonts>) => {
    const value = event.target.value as StandardFonts;
    formik.setFieldValue("fontFamily", value);
  };

  const handleLocationChange = (event: React.MouseEvent<HTMLElement>, newValue: QrCodeLocationType | null) => {
    if (newValue !== null) {
      formik.setFieldValue('location', newValue, false);
    }
  };

  const getFontDisplayName = (font: StandardFonts) => {
    const fontDisplayNames: { [key in StandardFonts]: string } = {
      [StandardFonts.Courier]: 'Courier',
      [StandardFonts.CourierBold]: 'Courier Bold',
      [StandardFonts.CourierOblique]: 'Courier Oblique',
      [StandardFonts.CourierBoldOblique]: 'Courier Bold Oblique',
      [StandardFonts.Helvetica]: 'Helvetica',
      [StandardFonts.HelveticaBold]: 'Helvetica Bold',
      [StandardFonts.HelveticaOblique]: 'Helvetica Oblique',
      [StandardFonts.HelveticaBoldOblique]: 'Helvetica Bold Oblique',
      [StandardFonts.TimesRoman]: 'Times Roman',
      [StandardFonts.TimesRomanBold]: 'Times Roman Bold',
      [StandardFonts.TimesRomanItalic]: 'Times Roman Italic',
      [StandardFonts.TimesRomanBoldItalic]: 'Times Roman Bold Italic',
      [StandardFonts.Symbol]: 'Symbol',
      [StandardFonts.ZapfDingbats]: 'Zapf Dingbats',
    };
    return fontDisplayNames[font];
  };

  const pageLayout = {
    transformSize: ({ size }: { size: { height: number; width: number } }) => ({
      height: size.height + 30,
      width: size.width + 30,
    }),
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'white',
    }),
  };

  return (
    <>
      <CustomAppBar backButton pageTitle={"Generate QR Codes"} />
      <Container>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <FormControl component="fieldset" fullWidth sx={{ mt: 4 }}>
              <Grid container spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={3} sx={{ p: 4, height: '657px' }}>
                    <Stack spacing={2} marginTop={"20px"}>
                      <Field
                        as={TextField}
                        name="numCodes"
                        label="Number of QR Codes"
                        type="number"
                        variant="outlined"
                        fullWidth
                        error={formik.touched.numCodes && Boolean(formik.errors.numCodes)}
                        helperText={formik.touched.numCodes && formik.errors.numCodes}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Field
                          as={FormControlLabel}
                          control={
                            <StandardSwitch
                              checked={formik.values.showHeaderText}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formik.setFieldValue("showHeaderText", e.target.checked);
                                if (e.target.checked) {
                                  formik.setFieldValue("headerText", "Scan to order");
                                }
                              }}
                            />
                          }
                          label=""
                          name="showHeaderText"
                        />
                        <Field
                          as={TextField}
                          name="headerText"
                          label="Header Text"
                          variant="outlined"
                          fullWidth
                          disabled={!formik.values.showHeaderText}
                          onBlur={() => {
                            if (formik.values.headerText.trim() === "") {
                              formik.setFieldValue("showHeaderText", false);
                            }
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(e);
                            if (!formik.values.showHeaderText) {
                              formik.setFieldValue("showHeaderText", true);
                            }
                          }}
                          error={formik.touched.headerText && Boolean(formik.errors.headerText)}
                          helperText={formik.touched.headerText && formik.errors.headerText}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Field
                          as={FormControlLabel}
                          control={
                            <StandardSwitch
                              checked={formik.values.showFooterText}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formik.setFieldValue("showFooterText", e.target.checked);
                                if (e.target.checked) {
                                  formik.setFieldValue("footerText", "Scannen für Bestellung");
                                }
                              }}
                            />
                          }
                          label=""
                          name="showFooterText"
                        />
                        <Field
                          as={TextField}
                          name="footerText"
                          label="Footer Text"
                          variant="outlined"
                          fullWidth
                          disabled={!formik.values.showFooterText}
                          onBlur={() => {
                            if (formik.values.footerText.trim() === "") {
                              formik.setFieldValue("showFooterText", false);
                            }
                          }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.handleChange(e);
                            if (!formik.values.showFooterText) {
                              formik.setFieldValue("showFooterText", true);
                            }
                          }}
                          error={formik.touched.footerText && Boolean(formik.errors.footerText)}
                          helperText={formik.touched.footerText && formik.errors.footerText}
                        />
                      </Box>
                      <Typography variant="body1">QR Code Colors</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MuiColorInput
                          label="Background"
                          value={formik.values.backgroundColor}
                          format="hex"
                          onChange={handleColorChange('backgroundColor')}
                        />
                        <MuiColorInput
                          label="Code"
                          value={formik.values.qrCodeColor}
                          format="hex"
                          onChange={handleColorChange('qrCodeColor')}
                        />
                      </Box>
                      <Typography variant="body1">Header and Footer Colors</Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MuiColorInput
                          label="Background"
                          value={formik.values.headerAndFooterBackgroundColor}
                          format="hex"
                          onChange={handleColorChange('headerAndFooterBackgroundColor')}
                        />
                        <MuiColorInput
                          label="Font"
                          value={formik.values.headerAndFooterFontColor}
                          format="hex"
                          onChange={handleColorChange('headerAndFooterFontColor')}
                        />
                      </Box>
                      <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ mr: 2 }}>
                            Font
                          </Typography>
                          <Select
                            name="fontFamily"
                            value={formik.values.fontFamily}
                            onChange={handleFontFamilyChange}
                            fullWidth
                          >
                            {supportedFonts.map((font) => (
                              <MenuItem key={font} value={font}>
                                {getFontDisplayName(font)}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </FormControl>
                      <Typography variant="body1">Location assigned to the QR code</Typography>
                      <FormControl fullWidth>
                        <ToggleButtonGroup
                          color="warning"
                          size="small"
                          value={formik.values.location}
                          exclusive
                          onChange={handleLocationChange}
                          aria-label="Location"
                          sx={{
                            width: '100%',
                            '& .MuiToggleButtonGroup-grouped': {
                              borderColor: 'black',
                              '&.Mui-selected, &.Mui-selected:hover': {
                                color: 'black',
                                backgroundColor: '#e84b4bee',
                                borderColor: 'black',
                              },
                              '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                              },
                            },
                          }}
                        >
                          <ToggleButton value={QrCodeLocationType.NAME}>
                            {formik.values.location === QrCodeLocationType.NAME ? "Assign optionally a location name (e.g., table number) on the Orders page" : "Location Name"}
                          </ToggleButton>
                          <ToggleButton
                            value={QrCodeLocationType.ADDRESS}
                            disabled={!customer.businessSettings.delivery}
                          >
                            {formik.values.location === QrCodeLocationType.ADDRESS ? "Customers can set any address for delivery during the ordering" : "Location Address"}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper elevation={3} sx={{ p: 4 }}>
                    {pdfUrl && (
                      <Box sx={{ mt: 1, height: '650px' }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@4.10.38/build/pdf.worker.min.mjs">
                          <div
                            style={{
                              border: '1px solid rgba(0, 0, 0, 0.3)',
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                          >
                            <div
                              style={{
                                alignItems: 'center',
                                backgroundColor: '#eeeeee',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '8px',
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Download>
                                  {(props: RenderDownloadProps) => (
                                    <Button
                                      style={{
                                        backgroundColor: '#e84b4bee',
                                        border: 'none',
                                        borderRadius: '4px',
                                        color: 'black',
                                        cursor: 'pointer',
                                        padding: '8px',
                                      }}
                                      onClick={() => {
                                        props.onClick();
                                        toast.success('Download started successfully!');
                                      }}
                                      type="button"
                                    >
                                      Download
                                    </Button>
                                  )}
                                </Download>
                              </Box>
                              <div>
                                Page <CurrentPageLabel /> of <CurrentPageLabel />
                              </div>
                            </div>
                            <div
                              style={{
                                flex: 1,
                                overflow: 'auto',
                              }}
                            >
                              <Viewer
                                fileUrl={pdfUrl}
                                plugins={[getFilePluginInstance, pageNavigationPluginInstance]}
                                pageLayout={pageLayout}
                              />
                            </div>
                          </div>
                        </Worker>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        </FormikProvider>
        <Box sx={{ mt: 4 }}>
          <Alert severity="info">
            <AlertTitle>QR Code Tips</AlertTitle>
            Want perfect QR codes? Have any questions? Read our tips here: <a href="https://f6st.com/qrtips" target="_blank">f6st.com/qrtips</a>
          </Alert>
        </Box>
      </Container>
    </>
  );
};
