import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppContext } from '../AppProvider';
import { Product, Size, IDUtil, ProductExtra, ProductCategory, I18nUtil, ProductsTextsUtil, Customer, ProductUtil, Log, ProductFilter } from 'base.f6st.com';
import { SecondaryButton, StandardButton, StandardSwitch } from 'common.f6st.com';
import { useFormik } from 'formik';
import * as yup from 'yup';

type SizesDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (product: Product) => void;
  product: Product;
  txt: ProductsTextsUtil;
};

const sizeValidationSchema = yup.object({
  name: yup.string().required("Size name is required"),
  price: yup
    .number()
    .required("Price is required")
    .min(0, "Price must be 0 or greater"),
});

export const SizesDialog: React.FC<SizesDialogProps> = ({ open, onClose, onSave, product, txt }) => {
  const customer = useAppContext().getCustomer();
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const [prices, setPrices] = useState<Size[]>(product.sizes || []);

  useEffect(() => {
    if (open) {
      setPrices(product.sizes || []);
      formik.resetForm();
    }
  }, [open, product]);

  const formik = useFormik({
    initialValues: { name: "", price: "" },
    validationSchema: sizeValidationSchema,
    onSubmit: (values) => {
      const newId = IDUtil.getShortId();
      txt.set(newId, values.name);
      const newSizePrice = parseFloat(values.price);
      const newPriceEntry: Size = { id: newId, price: newSizePrice };
      const updatedPrices = [...prices, newPriceEntry];
      setPrices(updatedPrices);
      onSave({ ...product, sizes: updatedPrices });
      onClose();
    },
  });

  const handleClose = () => {
    setPrices(product.sizes || []);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6" component="div">Add Sizes</Typography>
        <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            id="size-name"
            name="name"
            label="Size Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            slotProps={{ htmlInput: { maxLength: 30 } }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            id="size-price"
            name="price"
            label={`Price (${currency})`}
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            inputProps={{ min: 0 }}
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton action={handleClose} text="Cancel" />
          <StandardButton action={formik.handleSubmit} text="Add" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

type AddExtraDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (product: Product) => void;
  product: Product;
  txt: ProductsTextsUtil;
};

const extraValidationSchema = yup.object({
  name: yup.string().required("Extra name is required"),
  price: yup
    .number()
    .required("Price is required")
    .min(0, "Price must be 0 or greater"),
});

export const AddExtraDialog: React.FC<AddExtraDialogProps> = ({ open, onClose, onSave, product, txt }) => {
  const customer = useAppContext().getCustomer();
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const [extras, setExtras] = useState<ProductExtra[]>(product.extras);

  useEffect(() => {
    if (open) {
      setExtras(product.extras);
      formik.resetForm();
    }
  }, [open, product]);

  const formik = useFormik({
    initialValues: { name: "", price: "" },
    validationSchema: extraValidationSchema,
    onSubmit: (values) => {
      const newId = IDUtil.getShortId();
      txt.set(newId, values.name);
      const newExtraPrice = parseFloat(values.price);
      const newExtraEntry: ProductExtra = { id: newId, price: newExtraPrice };
      const updatedExtras = [...extras, newExtraEntry];
      setExtras(updatedExtras);
      onSave({ ...product, extras: updatedExtras });
      onClose();
    },
  });

  const handleClose = () => {
    setExtras(product.extras);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h6" component="div">Add Extra</Typography>
        <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            id="extra-name"
            name="name"
            label="Extra Name"
            variant="outlined"
            fullWidth
            margin="normal"
            slotProps={{ htmlInput: { maxLength: 30 } }}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            id="extra-price"
            name="price"
            label={`Price (${currency})`}
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            inputProps={{ min: 0 }}
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton action={handleClose} text="Cancel" />
          <StandardButton action={formik.handleSubmit} text="Add" />
        </DialogActions>
      </form>
    </Dialog>
  );
};


export interface CategoryDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (customer: Customer) => void;
  categoryId?: string; // Optional: If not provided, it indicates a new category
  customer: Customer;  // Required: To fetch and update category data
}

const categoryValidationSchema = yup.object({
  name: yup
    .string()
    .required('Category Name is required'),
  categoryVAT: yup
    .number()
    .nullable()
    .min(0, 'Custom VAT must be between 0 and 100')
    .max(100, 'Custom VAT must be between 0 and 100'),
});

export const CategoryDialog: React.FC<CategoryDialogProps> = ({ open, onClose, onSave, categoryId, customer }) => {
  const txt = new ProductsTextsUtil(customer!);

  const formik = useFormik({
    initialValues: {
      name: '',
      categoryVAT: undefined as number | undefined,
      customVAT: false,
    },
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => {
      let vat =
        values.customVAT && values.categoryVAT !== customer.businessSettings.defaultVAT
          ? values.categoryVAT
          : undefined;

      const updatedCategory: ProductCategory = {
        id: categoryId || IDUtil.getShortId(),
        categoryVAT: vat,
        products: categoryId ? ProductUtil.getProductCategory(customer!, categoryId).products : [],
      };

      const updatedCategories = categoryId
        ? customer!.products.categories.map((cat) =>
          cat.id === categoryId ? updatedCategory : cat
        )
        : [...customer!.products.categories, updatedCategory];

      const updatedCustomer = {
        ...customer!,
        products: {
          ...customer!.products,
          categories: updatedCategories,
        },
      };

      txt.set(updatedCategory.id, values.name);
      onSave(updatedCustomer);
    },
  });

  useEffect(() => {
    if (categoryId && customer) {
      const category = ProductUtil.getProductCategory(customer!, categoryId);
      formik.setValues({
        name: txt.get(category.id) || '',
        categoryVAT: category.categoryVAT || customer.businessSettings.defaultVAT,
        customVAT: category.categoryVAT !== undefined,
      });
    } else {
      formik.resetForm();
    }
  }, [categoryId, customer]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {categoryId ? 'Edit Category' : 'Add Category'}
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            label="Category Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ marginBottom: 1 }}
            fullWidth
            slotProps={{
              htmlInput: { maxLength: 30 }, // Replace inputProps with slotProps.htmlInput
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <FormControlLabel
            control={
              <StandardSwitch
                name="customVAT"
                checked={formik.values.customVAT}
                onChange={formik.handleChange}
                color="primary"
              />
            }
            label="Custom VAT"
          />

          {formik.values.customVAT && (
            <TextField
              label="Custom VAT Percentage"
              name="categoryVAT"
              type="number"
              value={formik.values.categoryVAT || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              slotProps={{
                htmlInput: { min: 0, max: 100 }, // Use only slotProps.htmlInput for constraints
              }}
              error={formik.touched.categoryVAT && Boolean(formik.errors.categoryVAT)}
              helperText={formik.touched.categoryVAT && formik.errors.categoryVAT}
              sx={{ display: formik.values.customVAT ? 'block' : 'none' }} // Hide when customVAT is false
            />
          )}
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 4 }}>
          <SecondaryButton action={handleClose} text="Cancel" />
          <StandardButton
            action={formik.handleSubmit}
            text="Save"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export interface FilterDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (customer: Customer) => void;
  filterId?: string; // Optional: If not provided, it indicates a new filter
  customer: Customer; // Required: To fetch and update filter data
}

// Validation schema
const filterValidationSchema = yup.object({
  name: yup
    .string()
    .required('Filter Name is required')
    .max(30, 'Filter Name must be at most 30 characters'),
});

export const FilterDialog: React.FC<FilterDialogProps> = ({ open, onClose, onSave, filterId, customer }) => {
  const txt = new ProductsTextsUtil(customer!);

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: filterValidationSchema,
    onSubmit: (values) => {
      const updatedFilter: ProductFilter = filterId
        ? { ...ProductUtil.getFilter(customer, filterId) }
        : { id: IDUtil.getShortId() };

      // Update the name in txt
      txt.set(updatedFilter.id, values.name);

      const updatedFilters = filterId
        ? customer.products.filters.map((filter) =>
            filter.id === filterId ? updatedFilter : filter
          )
        : [...customer.products.filters, updatedFilter];

      const updatedCustomer = {
        ...customer,
        products: {
          ...customer.products,
          filters: updatedFilters,
        },
      };

      onSave(updatedCustomer);
    },
  });

  useEffect(() => {
    if (filterId && customer) {
      const filter = ProductUtil.getFilter(customer, filterId);
      formik.setValues({
        name: txt.get(filter.id) || '',
      });
    } else {
      formik.resetForm();
    }
  }, [filterId, customer]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {filterId ? 'Edit Filter' : 'Add Filter'}
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            label="Filter Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            slotProps={{
              htmlInput: { maxLength: 30 }, // Enforce max length at the input level
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton action={handleClose} text="Cancel" />
          <StandardButton action={formik.handleSubmit} text="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
