import { useState, useEffect, useCallback, useRef } from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";
import { Draggable, Droppable, DragDropContext, DropResult } from "@hello-pangea/dnd";
import { DeleteConfirmDialog } from "./dialogs/DeleteConfirmDialog";

interface DraggableListProps<T> {
    items: T[];
    title: string;
    onItemSelect: (item: T) => void;
    onItemDelete: (item: T) => void;
    onAddItem: () => void;
    onDragEnd: (updatedItems: T[]) => void;
    defaultSelectedId: string;
    onItemEdit: (item: T) => void;
}

export const DraggableList = <T extends { id: string; name: string; fixed?: boolean }>({
    items,
    title,
    onItemSelect,
    onItemDelete,
    onAddItem,
    onDragEnd,
    defaultSelectedId,
    onItemEdit,
}: DraggableListProps<T>) => {
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<T | null>(null);

    const isFirstRender = useRef(true);
    const [renderState, setRenderState] = useState(0);

    const triggerRender = useCallback(() => {
        setRenderState((prev) => prev + 1);
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            const defaultItem = items.find((item) => item.id === defaultSelectedId);
            if (defaultItem) {
                setSelectedItem(defaultItem);
            }
            isFirstRender.current = false;
        }
    }, [items, defaultSelectedId]);

    const handleSelect = useCallback(
        (item: T) => {
            setSelectedItem(item);
            onItemSelect(item);
            triggerRender();
        },
        [onItemSelect, triggerRender]
    );

    const handleDelete = useCallback(
        (item: T) => {
            setItemToDelete(item);
            setDeleteDialogOpen(true);
            triggerRender();
        },
        [triggerRender]
    );

    const confirmDelete = useCallback(() => {
        if (itemToDelete) {
            onItemDelete(itemToDelete);
            setItemToDelete(null);
            setDeleteDialogOpen(false);
            triggerRender();
        }
    }, [itemToDelete, onItemDelete, triggerRender]);

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;
            if (!destination) return;

            // Check if either the source or destination is a fixed item (can't be moved)
            const sourceItem = items[source.index];
            const destinationItem = items[destination.index];
            if (sourceItem.fixed || destinationItem.fixed) return;

            const updatedItems = Array.from(items);
            const [movedItem] = updatedItems.splice(source.index, 1);
            updatedItems.splice(destination.index, 0, movedItem);
            onDragEnd(updatedItems);
            triggerRender();
        },
        [items, onDragEnd, triggerRender]
    );

    return (
        <Box sx={{ border: 1, borderColor: "grey.300", p: 2, borderRadius: 1 }}>
            <Typography variant="h6" display="flex" justifyContent="space-between" alignItems="center">
                {title}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={title}>
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => {
                                const isFixed = item.fixed === true;
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                        isDragDisabled={isFixed}
                                    >
                                        {(provided) => (
                                            <ListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                onClick={() => handleSelect(item)}
                                                sx={{
                                                    backgroundColor:
                                                        selectedItem?.id === item.id
                                                            ? "rgba(0, 0, 0, 0.3)"
                                                            : "transparent",
                                                    "&:hover": { backgroundColor: "rgba(100, 100, 100, 0.1)" },
                                                    cursor: isFixed ? "not-allowed" : "pointer",
                                                }}
                                            >
                                                <ListItemText primary={item.name} />
                                                <ListItemSecondaryAction>
                                                    {selectedItem?.id === item.id && !isFixed && (
                                                        <>
                                                            <IconButton
                                                                edge="end"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onItemEdit(item);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                edge="end"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDelete(item);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
            <IconButton onClick={onAddItem} style={{ width: "40px" }}>
                <AddIcon />
            </IconButton>
            <DeleteConfirmDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={confirmDelete}
            />
        </Box>
    );
};
