import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { CustomerClient, OrderClient, ReceiptLink, SecondaryButton, StandardButton, StandardSwitch, StripeUtil } from 'common.f6st.com';
import CloseIcon from '@mui/icons-material/Close';
import WebIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppProvider';
import { CallerType, CustomerUpdateAttributes, Log, Order, OrderStatus, OrderUtil, PaymentStatus } from 'base.f6st.com';

type OrderDetailDialogProps = {
  open: boolean;
  order: Order;
  onClose: () => void;
};

export const OrderDetailDialog: React.FC<OrderDetailDialogProps> = ({ open, order, onClose }) => {
  const customer = useAppContext().getCustomer();
  const initialLocation = customer.qrCodeLocationNames[order.qrCode.id] || "";
  const [editedLocation, setEditedLocation] = useState(initialLocation);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [priority, setPriority] = useState(order.priority);
  const [orderStatus, setOrderStatus] = useState(order.orderStatus);
  const [refundAmount, setRefundAmount] = useState(OrderUtil.getOrderAmount(order).total);

  useEffect(() => {
    setEditedLocation(customer.qrCodeLocationNames[order.qrCode.id] || "");
  }, [customer.qrCodeLocationNames, order.qrCode.id]);

  const handleEditLocation = () => {
    setIsEditingLocation(true);
  };

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriority(event.target.checked);
  };

  const handleOrderStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: OrderStatus) => {
    if (newStatus !== null && order.orderStatus !== OrderStatus.CANCELED) {
      setOrderStatus(newStatus);
      if (newStatus === OrderStatus.CANCELED && order.paymentStatus === PaymentStatus.PAID) {
        setRefundAmount(OrderUtil.getOrderAmount(order).total);
      }
    }
  };

  const handleRefundAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    const totalAmount = OrderUtil.getOrderAmount(order).total;
    if (value >= 0 && value <= totalAmount) {
      setRefundAmount(value);
    }
  };

  const handleDialogSave = async () => {
    let orderUpdateFields: { priority?: boolean; orderStatus?: OrderStatus; refundAmount?: number; refundDate?: string; paymentStatus?: PaymentStatus } = {};
    let customerUpdateNeeded = false;
    let changes: string[] = [];

    if (priority !== order.priority) {
      orderUpdateFields.priority = priority;
      changes.push('priority');
    }

    const currentLocation = customer.qrCodeLocationNames[order.qrCode.id] || "";
    const trimmedLocation = editedLocation.trim();
    if (trimmedLocation !== currentLocation) {
      customerUpdateNeeded = true;
      changes.push('location');
      if (trimmedLocation !== "") {
        customer.qrCodeLocationNames[order.qrCode.id] = trimmedLocation;
      } else {
        if (customer.qrCodeLocationNames[order.qrCode.id]) {
          delete customer.qrCodeLocationNames[order.qrCode.id];
        }
      }
    }

    if (orderStatus !== order.orderStatus) {
      orderUpdateFields.orderStatus = orderStatus;
      changes.push('status');
    }

    if (orderStatus === OrderStatus.CANCELED && order.paymentStatus === PaymentStatus.PAID) {
      orderUpdateFields.paymentStatus = PaymentStatus.REFUNDED;
      orderUpdateFields.refundAmount = refundAmount;
      orderUpdateFields.refundDate = new Date().toISOString();

      changes.push('refund amount', 'refund date', 'payment status');

      if (!order.stripeConnectedAccountId || !order.stripeChargeId) {
        Log.error(`Refund failed for Order ${order.id}: Missing Stripe account ID or charge ID`, {
          stripeConnectedAccountId: order.stripeConnectedAccountId,
          stripeChargeId: order.stripeChargeId,
        });
        toast.error(`Payment for Order ${order.id} cannot be refunded.`);
      } else {
        try {
          Log.debug(`Initiating refund for Order ${order.id}`, {
            stripeConnectedAccountId: order.stripeConnectedAccountId,
            stripeChargeId: order.stripeChargeId,
            refundAmount: order.refundAmount,
            currency: order.currency,
          });


          const refundAmountInCents = Math.round(refundAmount * 100); // convert to cents
          await StripeUtil.refund(order.stripeConnectedAccountId, order.stripeChargeId, refundAmountInCents);

          Log.debug(`Refund successful for Order ${order.id}`, {
            refundAmount: order.refundAmount,
            currency: order.currency,
          });

          toast.success(`Refund of ${refundAmount.toFixed(2)} ${order.currency} for Order ${order.id} has been processed successfully.`);
        } catch (error) {
          Log.error(`Refund failed for Order ${order.id}`, error);
          toast.error(`Failed to refund Order ${order.id}: ${(error as Error).message}`);
        }
      }
    }

    if (Object.keys(orderUpdateFields).length > 0) {
      await OrderClient.updateOrder(CallerType.ADMIN, order.id, order.customerId, orderUpdateFields);
    }

    if (customerUpdateNeeded) {
      await CustomerClient.updateCustomer(CallerType.ADMIN, order.customerId, [{
        attribute: CustomerUpdateAttributes.QR_CODE_LOCATION_NAMES,
        value: customer.qrCodeLocationNames
      }]);
    }

    if (changes.length > 0) {
      toast.success(`Order ${changes.join(', ')} updated successfully`);
    }

    onClose();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Order {OrderUtil.getOrderAmount(order).total.toFixed(2)} {order.currency}</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Order Time</TableCell>
              <TableCell>{new Date(order.date).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Payment Status</TableCell>
              <TableCell>{order.paymentStatus}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Order Status</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', my: 2 }}>
                  <ToggleButtonGroup
                    color="warning"
                    size="small"
                    value={orderStatus}
                    exclusive
                    onChange={handleOrderStatusChange}
                    aria-label="Order Status"
                    sx={{
                      '& .MuiToggleButtonGroup-grouped': {
                        borderColor: 'black',
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: 'black',
                          backgroundColor: '#e84b4bee',
                          borderColor: 'black',
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        },
                      },
                    }}
                  >
                    {Object.values(OrderStatus).map((status) => (
                      <ToggleButton key={status} value={status} disabled={order.orderStatus === OrderStatus.CANCELED}>
                        {status}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
                {orderStatus === OrderStatus.CANCELED && order.paymentStatus === PaymentStatus.PAID && (
                  <TextField
                    label={`Refund Amount (${order.currency})`}
                    type="number"
                    value={refundAmount}
                    onChange={handleRefundAmountChange}
                    inputProps={{ min: 0, max: OrderUtil.getOrderAmount(order).total, step: 0.01 }}
                    fullWidth
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Priority Order</TableCell>
              <TableCell>
                <StandardSwitch checked={priority} onChange={handlePriorityChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>QR Location <span style={{ fontWeight: 'normal' }}>e.g. table number</span></TableCell>
              <TableCell>
                {isEditingLocation ? (
                  <TextField value={editedLocation} onChange={(e) => setEditedLocation(e.target.value)} />
                ) : (
                  <>
                    {customer.qrCodeLocationNames[order.qrCode.id] || ""}
                    <IconButton onClick={handleEditLocation}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
            {order.items.some((item) => item.recommended) && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>AI Recommendation</TableCell>
                <TableCell>
                  {order.items
                    .filter((item) => item.recommended)
                    .map((item) => order.texts[item.product.id])
                    .join(', ')}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Receipt</TableCell>
              <TableCell>
                <ReceiptLink order={order} customer={customer} businessReceipt={false} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Identifiers</TableCell>
              <TableCell>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography>Order ID:&nbsp;</Typography>
                    <Typography>{order.id}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.id)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography>Buyer ID:&nbsp;</Typography>
                    <Typography>{order.customerId}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.customerId)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography>QR ID:&nbsp;</Typography>
                    <Typography>{order.qrCode.id}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.qrCode.id)}>
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton
                      component="a"
                      href={`https://order.f6st.com?c=${order.qrCode.id}:${order.qrCode.customerId}:${order.qrCode.locationType}`}
                      target="_blank"
                    >
                      <WebIcon />
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 2 }}>
          <SecondaryButton text="Cancel" action={onClose} />
          <StandardButton text="Save" action={handleDialogSave} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};
