import { useEffect } from "react";
import { Container, Grid, Grid2, Paper, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../components/AppProvider";
import { CustomAppBar } from "../components/CustomAppBar";
import { Footer } from "common.f6st.com";

type HomePageProps = {};

export const HomePage = ({}: HomePageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getLoggedInUser } = useAppContext();
  const loggedInUser = getLoggedInUser();

  useEffect(() => {
    if (!loggedInUser?.admin) {
      navigate("/orders");
    }
  }, [loggedInUser, navigate]);
  
  const cardsList = [
    {
      title: "Orders",
      description: "Manage your customer orders here.",
      url: "/orders",
      icon: "/assets/ic_orders.svg",
      color: "#E84B4B",
    },
    {
      title: "Products",
      description: "Configure and manage your products.",
      url: "/products",
      icon: "/assets/ic_products.svg",
      color: "#F4884C",
    },
    {
      title: "QR Code Designer",
      description: "Perfect for stands, stickers, and cards.",
      url: "/qr-codes",
      icon: "/assets/ic_qr_code.svg",
      color: "#65B9EA",
    },
    {
      title: "Business Settings",
      description: "Adjust all F6ST settings here.",
      url: "/business",
      icon: "/assets/ic_business_settings.svg",
      color: "#172339",
    },
    {
      title: "Users",
      description: "Manage user access and permissions.",
      url: "/users",
      icon: "/assets/ic_users.svg",
      color: "#EEB82B",
    }
  ];

  return (
    <>
      <CustomAppBar pageTitle="Home" />
      <Container>
        <Grid2 container spacing={2} columns={12} mt={2}>
          {cardsList.map((card) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={card.url}>
              <Link to={card.url} style={{ textDecoration: "none" }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "24px",
                    width: 290,
                    height: 108,
                    display: "flex",
                    flexDirection: "column",
                    color: "#FFF",
                    borderRadius: "16px",
                    gap: 2,
                    position: "relative",
                    backgroundColor: card.color,
                  }}
                >
                  <img width="32px" src={card.icon} alt={`${card.title} Icon`} />
                  <Stack direction="column" gap="4px">
                    <Typography variant="h5">{t(`${card.title}`)}</Typography>
                    <Typography color="#ffffff99" variant="body1">
                      {t(card.description)}
                    </Typography>
                  </Stack>
                  <img
                    src="/assets/card-img.png"
                    style={{ position: "absolute", top: 0, right: 0, height: "100%" }}
                    alt="Background Image"
                  />
                </Paper>
              </Link>
            </Grid>
          ))}
        </Grid2>
      </Container>
      <Footer stick terms="true" feedback="true" />
    </>
  );
};