import { useState } from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../components/AppProvider';
import { toast } from "react-toastify";
import { BusinessSettingsForm } from '../components/BusinessSettingsForm';
import { RegistrationPageForm } from '../components/RegistrationPageForm';
import { CustomAppBar } from '../components/CustomAppBar';
import { StripeOnboarding } from '../components/stripe/StripeOnboarding';
import { Formik } from 'formik';
import * as yup from 'yup';
import { User, IDUtil, AppError, Customer, BusinessSettings, Log, CallerType } from 'base.f6st.com';
import { StripeUtil, UserUtil, MailClient, SecurityCodeClient, LoginClient, CustomerClient } from 'common.f6st.com';

export type RegistrationPageInputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  countryCode: string;
  registrationVerificationCode?: string;
};

const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  email: yup.string().trim().email('Invalid email format').required('Email is required'),
  password: yup.string().trim().min(8, 'Password must be at least 8 characters').required('Password is required'),
  confirmPassword: yup.string().trim().oneOf([yup.ref('password')], 'Passwords must match').required('Confirm Password is required'),
  countryCode: yup.string().trim().required('Country Code is required'),
});

export const RegistrationPage = () => {
  const navigate = useNavigate();
  const { login, setCustomer, getCustomer } = useAppContext(); // Moved useAppContext to the top level
  const [stepDetail, setStepDetail] = useState({ step: 1, title: 'Your User Data' });
  const [message, setMessage] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const [codeId, setCodeId] = useState<string | undefined>(undefined);
  const [connectedAccountId, setConnectedAccountId] = useState<string | undefined>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const customerId = IDUtil.getShortId();
  const totalSteps = 3;

  const setStep = (step: number, title: string) => {
    setStepDetail({ step, title });
  };

  const initializeStripeOnboarding = async (customer: Customer) => {
    try {
      const { clientSecret, accountId } = await StripeUtil.createAccountSession(customer);
      if (!clientSecret || !accountId) throw new AppError("Failed to create Stripe account session");
      setClientSecret(clientSecret);
      setConnectedAccountId(accountId);
      setStep(3, 'Stripe Onboarding');
    } catch (err) {
      throw new AppError("Failed to initialize Connect signup", err);
    }
  };

  const handleStep1Finished = async (
    values: RegistrationPageInputs,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    const { email, password, confirmPassword, name, countryCode } = values;

    try {
      const userId = (await LoginClient.loginRegistration()).userId;
      const trimmedEmail = email.trim();
      const emailExists = await MailClient.isEmailExists(CallerType.ADMIN, trimmedEmail);

      if (emailExists) {
        setFieldError('email', 'This email is already registered.');
        return;
      }

      const passwordCheckResult = UserUtil.checkPassword(password, confirmPassword);
      if (passwordCheckResult === UserUtil.PASSWORD_COMPLEXITY_ERROR) {
        setFieldError('password', 'Password does not meet complexity requirements.');
        return;
      }
      if (passwordCheckResult === UserUtil.PASSWORD_MISMATCH_ERROR) {
        setFieldError('confirmPassword', 'Passwords do not match.');
        return;
      }

      const newCustomer: Customer = {
        id: customerId,
        active: true,
        businessSettings: { countryCode: countryCode } as BusinessSettings,
        orderSettings: {
          orderingOpen: true,
        },
        products: {
          categories: [],
          filters: [],
          productsTextsVersion: '',
        },
        qrCodeLocationNames: {}
      };

      const newUser: User = {
        id: userId,
        customerId: customerId,
        email: trimmedEmail,
        active: true,
        name: name.trim(),
        password: password.trim(),
        admin: true,
      };

      setUser(newUser);
      setCustomer(newCustomer);
      const subject = "F6ST verification code";
      const body = "Your verification code is: {code}";
      const generatedCodeId = await SecurityCodeClient.createSecurityCodeMail(CallerType.ADMIN, trimmedEmail, subject, body);
      if (generatedCodeId) {
        setCodeId(generatedCodeId);
        toast.success("Verification code sent to your email");
      } else {
        toast.error("Error sending your verification code");
      }
    } catch (error) {
      const errorMessage = String(error);
      if (errorMessage.includes("Email address is not verified")) {
        setMessage('The entered e-mail address cannot be used');
        setFieldError('email', 'The entered e-mail address cannot be used.');
      } else {
        throw error;
      }
    }

    setStep(2, 'Business Settings');
  }; 

  const handleStep2Finished = async (businessSettings: BusinessSettings) => {
    const customer = getCustomer();
    customer.businessSettings = businessSettings;
    Log.debug("Updating the business settings of the customer", businessSettings);
    await initializeStripeOnboarding(customer);
    setStep(3, 'Payment Setup');
  };

  const finishRegistration = async () => {
    const customer = getCustomer();
    if (connectedAccountId) {
      customer.businessSettings.connectedAccountId = connectedAccountId;
    }

    if (!user) throw new AppError("User not found");

    const password = user.password; // in local mode the password get overwritten
    await CustomerClient.createCustomer(CallerType.ADMIN, customer, user);

    if (user && password) {
      Log.debug("Logging in user", user)
      await login(user.id, password);

      // sending welcome mail
      const subject = "Welcome to F6ST";
      const body = `
        Hello,
      
        Welcome to F6ST!
        Use the following User Code during your next logins:
      
        ${user.id}
      
        Best regards,
        The F6ST Team
      `;
      MailClient.sendEmail(CallerType.ADMIN, user.email, subject, body);
      toast.success("Registration successful");
      navigate('/');
    }
  };

  return (
    <>
      <CustomAppBar pageTitle={`Registration ${stepDetail.step} of ${totalSteps} - ${stepDetail.title}`} showProfile={false} />
      <Container sx={{ maxWidth: '800px', marginX: 'auto' }}>
        <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
          {stepDetail.step === 1 && (
            <Formik
              initialValues={{
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                countryCode: '',
              }}
              validationSchema={validationSchema}
              /**
               * **Modified onSubmit to handle field errors**
               * - Passed setFieldError to handleStep1Finished
               * - Made the function async to await handleStep1Finished
               */
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                await handleStep1Finished(values, setFieldError);
                setSubmitting(false);
              }}
            >
              {({ errors, touched }) => (
                <RegistrationPageForm errors={errors} touched={touched} message={message} />
              )}
            </Formik>
          )}
          {stepDetail.step === 2 && (
            <Box>
              <BusinessSettingsForm
                onSubmitCallback={handleStep2Finished}
                useCase="REGISTRATION"
                userLoginCode={user?.id}
                codeId={codeId}
              />
            </Box>
          )}
          {stepDetail.step === 3 && (
            <Box>
              <StripeOnboarding
                clientSecret={clientSecret!}
                customer={getCustomer()}
                onOnboardingExit={() => finishRegistration()}
              />
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};
