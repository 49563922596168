import { Customer, Product, ProductsTexts, Products, ProductCategory, ProductFilter } from "../types/CustomerTypes.js";
import { AppError } from "./AppError.js";

export class ProductUtil {

  // find the id of the category of the given product 
  public static getProductCategoryId(customer: Customer, productId: string): string | undefined {
    const products = customer.products;

    if (!products) {
      return undefined;
    }

    for (const category of products.categories) {
      if (category.products.some((product: Product) => product.id === productId)) {
        return category.id;
      }
    }
    return undefined;
  }

  public static getProductsTexts(customer: Customer, languageCode?: string): ProductsTexts {
    if (!languageCode || languageCode.trim().length == 0) {
      languageCode = customer.businessSettings.languageCodePrimary;
    }
    const productKey = `products_${languageCode}`;
    const products = customer[productKey] as ProductsTexts;

    if (!products) {
      throw new AppError("Products not found for language", languageCode);
    }

    return products;
  }

  public static removeProductsTexts(customer: Customer, languageCode: string): void {
    customer[`products_${languageCode}`] = undefined;
  }
  public static setProductsTexts(customer: Customer, languageCode: string, products: ProductsTexts): void {
    customer[`products_${languageCode}`] = products;
  }

  public static isProductsTexts(customer: Customer, languageCode: string): boolean {
    const productKey = `products_${languageCode}`;
    return !!customer[productKey];
  }

  public static isCategories(products: Products): boolean {
    return products.categories && products.categories.length > 0;
  }

  public static getProductCategory(customer: Customer, categoryId: string): ProductCategory {

    if (!categoryId)
      throw new AppError("Product category id is empty");

    const products = customer.products;

    if (!products || !products.categories) {
      throw new AppError("No products or categories found for customer", customer.id);
    }

    // Find the category by categoryId
    const category = products.categories.find((category: ProductCategory) => category.id === categoryId);

    if (!category) {
      throw new AppError("Product category not found", categoryId);
    }

    return category;  // Return the found category
  }

  public static getProduct(customer: Customer, productId: string): Product {
    const products = customer.products;

    if (!products || !products.categories) {
      throw new AppError("No products or categories found for customer", customer.id);
    }

    // Loop through the categories and find the product by productId
    for (const category of products.categories) {
      const product = category.products.find((product: Product) => product.id === productId);

      if (product) {
        return product;  // Return the found product
      }
    }

    // If product is not found, throw an error
    throw new AppError("Product not found", productId);
  }

  public static getFilter(customer: Customer, filterId: string): ProductFilter {
    const products = customer.products;

    if (!products || !products.filters) {
      throw new AppError("No filters found for customer", customer.id);
    }

    // Find the filter by filterId
    const filter = products.filters.find((filter: ProductFilter) => filter.id === filterId);

    if (!filter) {
      throw new AppError("Product filter not found", filterId);
    }

    return filter;  // Return the found filter
  }
}

