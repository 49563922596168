import { Address, Product } from './CustomerTypes.js'; 
import { QrCode } from './QrCodeTypes.js';

export type OrderItem = {
  id: string;
  product: Product;
  quantity: number;
  categoryId: string; // snapshot of the category id
  vatPercentage: number; // item's VAT, either the default VAT or a product specific one 
  sizeId?: string; // id of size in the product.sizes which is selected (if product has multiple sizes, otherwise undefined and product.price is used)
  extraIds?: string[]; // id of size in the product.extras which are selected
  recommended?: boolean; // product was recommended by F6ST AI
}

export enum PaymentStatus {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  PAY_IN_PERSON = 'PAY_IN_PERSON',
  REFUNDED = 'REFUNDED', // refunded after cancellation 
}

export enum OrderStatus {
  NEW = 'NEW', // each order has this status created
  READY = 'READY', // order is ready to be delivered
  DELIVERED = 'DELIVERED', // customer can mark the order as delivered, buyer can also see it
  CANCELED = 'CANCELED' // order is canceled by the customer, potentially refunded 
}

export type DeliveryOrderDetails = {
  fullName: string;           // Recipient's full name
  phoneNumber: string;        // Recipient's contact number
  deliveryInstructions?: string; // Additional delivery instructions (e.g., floor number, landmarks)
  address: Address;           // Recipient's delivery address
  deliveryFee: number
};

export type BusinessDetails = {
  companyName: string;
  companyAddress: string;
  vatID?: string;
};

export type Order = {
  id: string; // unique id generated by the system, parition key in DB
  customerId: string; // customer of F6ST e.g. restaurant
  buyerId: string; // customer of the order e.g. user at restaurant
  date: Date; // order creation & payment date
  items: OrderItem[]; // ordered items
  orderStatus: OrderStatus;
  paymentStatus: PaymentStatus;
  currency: string; // order, payment currency to display
  texts: { [key: string]: string }; // All product and and prodct extra, categoyr texts for the particular receipts in business language
  totalAmount: number; // total order amount calculated at the time of order creation 
  qrCode: QrCode; // QR code used for initating the order
  refundDate?: Date; // date for the refund, only full refund is supported
  stripeChargeId?: string; // Stripe charge ID for refund
  stripeConnectedAccountId?: string; // Stripe charge ID for refund
  refundAmount?: number; // amount refunded in case of a refund
  discountPercentage?: number; // percentage discount of the order total amount
  tipPercentage?: number; // percentage tip of the net order amount
  serviceFeePercentage?: number; // percentage of the service fee of the total order amount  
  deliveryFee?: number; // delivery fee in case of delivery 
  priority?: boolean; // orders to handle with higher priority
  testMode?: boolean; // if the business settings is in test mode
  deliveryDetails?: DeliveryOrderDetails; // delivery information if the given QR ordrer was a delivery one, undefined if there was no delivery
  paymentMethod?: string; // Payment method e.g. Card, PayPal, coming from Stripe, undefined for in case of in person payment
  businessDetails?: BusinessDetails; // Business details for receipt
}

export type ProductRecommendation = {
  message: string; // Text message to the customer
  productId: string; // Recommended product
  categoryId: string; // Recommended product's category
}