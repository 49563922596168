import { useState, useEffect, useRef } from "react";
import {
    Paper,
    Container,
    Stack,
    Typography,
    Box,
    Grid,
    Grid2,
    IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { useAppContext } from "../components/AppProvider";
import { DraggableList } from "../components/DraggableList";
import { CustomAppBar } from "../components/CustomAppBar";
import {
    ProductsTextsUtil,
    CallerType,
    Customer,
    ProductCategory,
    ProductFilter,
    ProductUtil,
    Product,
} from "base.f6st.com";
import {
    S3Client,
    CustomerClient,
    StandardButton,
    ProductCard
} from "common.f6st.com";
import { CardUseCase } from "common.f6st.com/src/gui/ProductCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CategoryDialog, FilterDialog } from "../components/dialogs/ProductDialogs";

// Constants for "all" and "noFilter"
const ALL_CATEGORY_ID = "all";
const NO_FILTER_ID = "noFilter";

interface ProductCategoryFixed extends ProductCategory {
    fixed?: boolean;
}

interface ProductFilterFixed extends ProductFilter {
    fixed?: boolean;
}

export const ProductsPage = () => {
    const { getCustomer, loadCustomer } = useAppContext();
    const [categories, setCategories] = useState<ProductCategoryFixed[]>([]);
    const [filters, setFilters] = useState<ProductFilterFixed[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(undefined);
    const [selectedFilterId, setSelectedFilterId] = useState<string | undefined>(undefined);
    const [txt, setTxt] = useState<ProductsTextsUtil | undefined>(undefined);
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [dialogOpenCategory, setDialogOpenCategory] = useState(false);
    const [dialogOpenFilter, setDialogOpenFilter] = useState(false);
    const [categoryToEdit, setCategoryToEdit] = useState<string | undefined>(undefined); // New state for category editing
    const [filterToEdit, setFilterToEdit] = useState<string | undefined>(undefined); // New state for filter editing
    const location = useLocation();
    const fetchInitiated = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            let customerCurrent: Customer = location.state?.customer;

            if (!customerCurrent) {
                const customerToLoad = getCustomer();
                customerCurrent = await loadCustomer(
                    customerToLoad.id,
                    customerToLoad.businessSettings.languageCodePrimary
                );
            }

            const txt = new ProductsTextsUtil(customerCurrent);
            const products = customerCurrent.products;

            const allCategory: ProductCategoryFixed = {
                id: ALL_CATEGORY_ID,
                products: products.categories.flatMap((cat) => cat.products),
                fixed: true,
            };

            const copiedCategories = [
                allCategory,
                ...products.categories.map((category) => ({ ...category })),
            ];

            const allFilter: ProductFilterFixed = { id: ALL_CATEGORY_ID, fixed: true };
            const noFilter: ProductFilterFixed = { id: NO_FILTER_ID, fixed: true };
            const copiedFilters = [
                allFilter,
                noFilter,
                ...products.filters.map((filter) => ({ ...filter })),
            ];

            const selectedCategoryId = location.state?.selectedCategoryId;
            setSelectedCategoryId(selectedCategoryId ? selectedCategoryId : ALL_CATEGORY_ID);
            setCustomer(customerCurrent);
            setCategories(copiedCategories);
            setFilters(copiedFilters);
            setTxt(txt);
            setSelectedFilterId(ALL_CATEGORY_ID);
        };

        if (!fetchInitiated.current) {
            fetchProducts();
            fetchInitiated.current = true;
        }
    }, []);

    if (!txt || !customer || !selectedCategoryId) {
        return <Typography>Loading...</Typography>;
    }

    const countProductsInCategory = (categoryId: string, filterId?: string) => {
        const isFilterAll = !filterId || filterId === ALL_CATEGORY_ID;
        const isFilterNoFilter = filterId === NO_FILTER_ID;

        if (categoryId === ALL_CATEGORY_ID) {
            return customer?.products.categories.reduce((count, category) => {
                return count + category.products.filter(product => {
                    if (isFilterAll) return true;
                    if (isFilterNoFilter) return !product.filterIds?.length;
                    return product.filterIds?.includes(filterId);
                }).length;
            }, 0) || 0;
        }

        const category = ProductUtil.getProductCategory(customer!, categoryId);
        if (!category) return 0;

        return category.products.filter(product => {
            if (isFilterAll) return true;
            if (isFilterNoFilter) return !product.filterIds?.length;
            return product.filterIds?.includes(filterId);
        }).length;
    };

    const handleCategorySelected = (category: ProductCategoryFixed) => {
        setSelectedCategoryId(category.id);
    };

    const handleFilterSelected = (filter: ProductFilterFixed) => {
        setSelectedFilterId(filter.id);
    };

    const handleAddCategory = () => {
        setCategoryToEdit(undefined); // Reset for new category
        setDialogOpenCategory(true);
    };

    const handleEditCategory = (categoryId: string) => {
        setCategoryToEdit(categoryId); // Set for editing existing category
        setDialogOpenCategory(true);
    };

    const handleDeleteCategory = (category: ProductCategoryFixed) => {
        if (txt) {
            txt.remove(category.id);
        }

        const newCategories = categories.filter(cat => cat.id !== category.id);
        setCategories(newCategories);

        if (customer) {
            const updatedCategories = newCategories.filter(cat => cat.id !== ALL_CATEGORY_ID);
            const updatedCustomer = {
                ...customer,
                products: {
                    ...customer.products,
                    categories: updatedCategories as ProductCategory[],
                },
            };
            setCustomer(updatedCustomer);
        }

        if (selectedCategoryId === category.id) {
            setSelectedCategoryId(ALL_CATEGORY_ID);
        }
    };

    const handleAddFilter = () => {
        setFilterToEdit(undefined); // Reset for new filter
        setDialogOpenFilter(true);
    };

    const handleEditFilter = (filterId: string) => {
        setFilterToEdit(filterId); // Set for editing existing filter
        setDialogOpenFilter(true);
    };

    const handleDeleteFilter = (filter: ProductFilterFixed) => {
        const updatedFilters = filters.filter(f => f.id !== filter.id);

        if (txt) {
            txt.remove(filter.id);
        }

        const updatedCategories = categories.map(cat => ({
            ...cat,
            products: cat.products.map(prod => {
                const updatedFilterIds = prod.filterIds?.filter(id => id !== filter.id) || [];
                return {
                    ...prod,
                    filterIds: updatedFilterIds.length > 0 ? updatedFilterIds : undefined,
                };
            }),
        }));

        const filtersWithoutAllAndNoFilter = updatedFilters.filter(f => f.id !== ALL_CATEGORY_ID && f.id !== NO_FILTER_ID);
        const updatedCategoriesWithoutAll = updatedCategories.filter(cat => cat.id !== ALL_CATEGORY_ID);

        const updatedCustomer = {
            ...customer,
            products: {
                ...customer.products,
                categories: updatedCategoriesWithoutAll as ProductCategory[],
                filters: filtersWithoutAllAndNoFilter as ProductFilter[],
            },
        };

        setFilters(updatedFilters);
        setCategories(updatedCategories);
        setCustomer(updatedCustomer);

        if (selectedFilterId === filter.id) {
            setSelectedFilterId(ALL_CATEGORY_ID);
        }
    };

    const handleSave = async () => {
        if (!txt || !customer) return;

        const imageIdsToKeep: string[] = customer.products.categories.flatMap(category =>
            category.products.flatMap(product => product.imgId || [])
        );

        const products = {
            ...customer.products,
            categories: categories.filter(cat => cat.id !== ALL_CATEGORY_ID),
            filters: filters.filter(filter => filter.id !== ALL_CATEGORY_ID && filter.id !== NO_FILTER_ID),
        };

        try {
            await S3Client.cleanup(CallerType.ADMIN, customer.id, imageIdsToKeep);

            await CustomerClient.updateProductsPrimary(
                CallerType.ADMIN,
                customer.id,
                products,
                txt.allProductsTexts,
                customer.businessSettings.languageCodePrimary
            );

            toast.success("Products saved successfully");

            navigate('/');

        } catch (error) {
            toast.error("Failed to save products. Please try again.");
            console.error("Error saving products:", error);
        }
    };

    const handleDialogCloseCategory = () => {
        setDialogOpenCategory(false);
    };

    const handleDialogCloseFilter = () => {
        setDialogOpenFilter(false);
    };

    const getFilteredProducts = (): Product[] => {
        if (selectedCategoryId === ALL_CATEGORY_ID) {
            return customer?.products.categories.flatMap(category =>
                category.products.filter(product => {
                    if (!selectedFilterId || selectedFilterId === ALL_CATEGORY_ID) return true;
                    if (selectedFilterId === NO_FILTER_ID) {
                        return !product.filterIds || product.filterIds.length === 0;
                    }
                    return product.filterIds?.includes(selectedFilterId) || false;
                })
            ) || [];
        }

        const category = ProductUtil.getProductCategory(customer!, selectedCategoryId);
        const products = category ? category.products : [];

        return products.filter(product => {
            if (!selectedFilterId || selectedFilterId === ALL_CATEGORY_ID) return true;
            if (selectedFilterId === NO_FILTER_ID) {
                return !product.filterIds || product.filterIds.length === 0;
            }
            return product.filterIds?.includes(selectedFilterId) || false;
        });
    };

    const getCategoryItems = (): { id: string; name: string; fixed?: boolean }[] => {
        return categories.map(category => {
            const defaultName =
                category.id === ALL_CATEGORY_ID
                    ? "All"
                    : txt.get(category.id) || "Unnamed Category";
            const productCount = countProductsInCategory(
                category.id,
                selectedFilterId
            );
            const nameWithCount = category.id !== ALL_CATEGORY_ID
                ? `${defaultName} (${productCount})`
                : `${defaultName} (${productCount})`;

            return {
                id: category.id,
                name: nameWithCount,
                fixed: category.fixed,
            };
        });
    };

    const handleCategorySave = (customer: Customer) => {
        const allCategory: ProductCategoryFixed = {
            id: ALL_CATEGORY_ID,
            products: customer.products.categories.flatMap((cat) => cat.products),
            fixed: true,
        };
    
        const updatedCategories: ProductCategoryFixed[] = [
            allCategory,
            ...customer.products.categories.map((category) => ({ ...category })),
        ];
    
        setCategories(updatedCategories);
        setCustomer(customer);
        setDialogOpenCategory(false);
    };
    
    const handleFilterSave = (customer: Customer) => {
        const allFilter: ProductFilterFixed = { id: ALL_CATEGORY_ID, fixed: true };
        const noFilter: ProductFilterFixed = { id: NO_FILTER_ID, fixed: true };
    
        const updatedFilters: ProductFilterFixed[] = [
            allFilter,
            noFilter,
            ...customer.products.filters.map((filter) => ({ ...filter })),
        ];
    
        setFilters(updatedFilters);
        setCustomer(customer);
        setDialogOpenFilter(false);
    };
    
    return (
        <>
            <CustomAppBar backButton backButtonUrl="/" pageTitle="Products" />
            <Container>
                <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <DraggableList
                                items={getCategoryItems()} // Use the transformed data
                                title="Categories"
                                onItemSelect={(item) => handleCategorySelected(categories.find(cat => cat.id === item.id)!)}
                                onItemDelete={(item) => handleDeleteCategory(categories.find(cat => cat.id === item.id)!)}
                                onItemEdit={(item) => handleEditCategory(item.id)} 
                                onAddItem={handleAddCategory}
                                onDragEnd={(updatedItems) => {
                                    const updatedCategories = updatedItems.map(item => {
                                        const originalCategory = categories.find(cat => cat.id === item.id);
                                        return { ...originalCategory, name: item.name, fixed: item.fixed } as ProductCategoryFixed;
                                    });
                                    setCategories(updatedCategories);
                                }}
                                defaultSelectedId={selectedCategoryId || ALL_CATEGORY_ID}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DraggableList
                                items={filters.map(filter => {
                                    const defaultName = filter.id === ALL_CATEGORY_ID
                                        ? "All"
                                        : filter.id === NO_FILTER_ID
                                            ? "No Filter"
                                            : txt.get(filter.id) || "Unnamed Filter";
                                    const totalProductsWithFilter = countProductsInCategory(
                                        selectedCategoryId,
                                        filter.id
                                    );
                                    const nameWithCount = `${defaultName} (${totalProductsWithFilter})`

                                    return {
                                        ...filter,
                                        name: nameWithCount,
                                        fixed: filter.id === ALL_CATEGORY_ID || filter.id === NO_FILTER_ID,
                                    };
                                })}
                                title="Filters"
                                onItemSelect={handleFilterSelected}
                                onItemDelete={handleDeleteFilter}
                                onItemEdit={(filter) => handleEditFilter(filter.id)}
                                onAddItem={handleAddFilter}
                                onDragEnd={(updatedItems: ProductFilterFixed[]) =>
                                    setFilters(updatedItems)
                                }
                                defaultSelectedId={ALL_CATEGORY_ID}
                            />
                        </Grid>
                    </Grid>

                    <Container>
                        <Box display='flex' flexDirection='column' gap={2} pb={12} mt={4}>
                            {selectedCategoryId && (
                                <Grid container spacing={3}>

                                    <Box mt={2} display='flex' flexDirection='column' gap={2} key={selectedCategoryId} id={selectedCategoryId}>
                                        <Grid2 container spacing={2}>
                                            {getFilteredProducts().map((product) => {
                                                return (
                                                    <Link
                                                        key={product.id}
                                                        style={{ textDecoration: "none" }}
                                                        to={`/product/${product.id}`}
                                                        state={{ customer, productId: product.id, selectedCategoryId: selectedCategoryId }}
                                                    >
                                                        <ProductCard
                                                            key={product.id}
                                                            product={product}
                                                            categoryId={selectedCategoryId}
                                                            customer={customer}
                                                            useCase={CardUseCase.ADMIN}
                                                            txt={txt}
                                                        />
                                                    </Link>
                                                );
                                            })}

                                            {/* Add New Product Button */}
                                            {selectedCategoryId !== ALL_CATEGORY_ID && (
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Link
                                                        to={`/product/add}`}
                                                        state={{ customer, selectedCategoryId }}
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <Paper
                                                            elevation={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                                p: 2,
                                                                minWidth: '310px',
                                                                minHeight: '108px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <IconButton
                                                                style={{ width: '100px', height: '100px' }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Paper>
                                                    </Link>
                                                </Grid>
                                            )}

                                        </Grid2>
                                    </Box>

                                </Grid>
                            )}
                        </Box>
                    </Container>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: -6 }}
                    >
                        {categories.length > 0 && (
                            <StandardButton text="Save All" action={handleSave} />
                        )}
                    </Stack>
                </Paper>
            </Container>


            {/* Category Dialog */}
            {dialogOpenCategory && (
                <CategoryDialog
                    open={dialogOpenCategory}
                    onClose={handleDialogCloseCategory}
                    onSave={handleCategorySave}
                    categoryId={categoryToEdit}  // Use categoryToEdit here
                    customer={customer}
                />
            )}

            {/* Filter Dialog */}
            {dialogOpenFilter && (
                <FilterDialog
                    open={dialogOpenFilter}
                    onClose={handleDialogCloseFilter}
                    onSave={handleFilterSave}
                    filterId={filterToEdit}  // Use filterToEdit here
                    customer={customer}
                />
            )}
        </>
    );
};
