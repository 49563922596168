export enum QrCodeLocationType  {
    NAME = '1',
    ADDRESS = '2',
}

export type QrCode = {
    id: string;
    customerId: string;
    locationType: QrCodeLocationType
}


