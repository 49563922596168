import { Customer, Order, OrderUtil, PaymentStatus, AppError } from "base.f6st.com";
import { PDFDocument, StandardFonts, PDFFont, rgb } from "pdf-lib";

export class ReceiptPdfUtil {
  static async generateReceiptPDF(
    order: Order,
    customer: Customer,
    translatedLabels: Record<string, string>
  ): Promise<Uint8Array> {
    // First call to renderPDF with a large initial pageHeight
    const initialPageHeight = 1000;
    const { contentHeight } = await this.renderPDF(
      order,
      customer,
      translatedLabels,
      initialPageHeight,
      true // Skip generating PDF bytes in the first call
    );

    // Calculate the total page height (top and bottom margins)
    const margin = 10;
    const bottomMargin = 15; // reduced bottom gap between content and footer
    const pageHeight = margin + contentHeight + bottomMargin;

    // Second call to renderPDF with adjusted pageHeight
    const { pdfBytes } = await this.renderPDF(
      order,
      customer,
      translatedLabels,
      pageHeight,
      false // Generate the final PDF bytes
    );

    if (!pdfBytes) throw new AppError("Invalid PDF generation");

    return pdfBytes;
  }

  static async renderPDF(
    order: Order,
    customer: Customer,
    translatedLabels: Record<string, string>,
    pageHeight: number,
    skipSaving: boolean
  ): Promise<{ pdfBytes?: Uint8Array; contentHeight: number }> {
    const pdfDoc = await PDFDocument.create();
    const pageWidth = 227; // Approx. 80mm in points
    const page = pdfDoc.addPage([pageWidth, pageHeight]);

    // Embed fonts
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // *** TEST MODE WATERMARK ***
    if (order.testMode) {
      const watermarkText = "TEST";
      const watermarkFontSize = 15;
      const watermarkMargin = 10;
      const textWidth = boldFont.widthOfTextAtSize(watermarkText, watermarkFontSize);
      const xPosition = pageWidth - watermarkMargin - textWidth;
      const yPosition = pageHeight - watermarkMargin - watermarkFontSize;
      page.drawText(watermarkText, {
        x: xPosition,
        y: yPosition,
        size: watermarkFontSize,
        font: boldFont,
        color: rgb(0.8, 0.8, 0.8),
      });
    }

    // Define constants for layout
    const margin = 10;
    const lineHeight = 12;
    const topMargin = margin;
    const initialGap = 20;
    const productNameColumnWidth = 90; // Adjust as needed

    // Start drawing from the top margin
    let currentY = pageHeight - topMargin;
    const initialY = currentY;
    let minY = currentY;

    // Add gap at the top
    currentY -= initialGap;

    // Business details
    const { businessName, address, languageCodePrimary, vatID, phoneNumber } = customer.businessSettings;
    const { street, zip, city } = address;
    const businessAddress = `${street}, ${zip} ${city}`;

    page.drawText(businessName, { x: margin, y: currentY, size: 10, font: boldFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    page.drawText(businessAddress, { x: margin, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    page.drawText(`${translatedLabels['vatID']}: ${vatID}`, { x: margin, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    page.drawText(`${translatedLabels['phone']}: ${phoneNumber}`, { x: margin, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Order details
    const orderDate = new Date(order.date);
    const formattedDate = orderDate.toLocaleDateString(languageCodePrimary);
    const formattedTime = orderDate.toLocaleTimeString(languageCodePrimary);

    page.drawText(`${translatedLabels['orderDate']}: ${formattedDate} ${formattedTime}`, { x: margin, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Payment details
    page.drawText(
      `${translatedLabels['paymentMethod']}: ${translatedLabels['translatedPaymentMethod']}`,
      { x: margin, y: currentY, size: 10, font: regularFont }
    );
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Show Payment Status (e.g., REFUNDED)
    page.drawText(
      `${translatedLabels['paymentStatus']}: ${translatedLabels['translatedPaymentStatus']}`,
      { x: margin, y: currentY, size: 10, font: regularFont }
    );
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Refund date
    if (order.refundDate) {
      const refundDateObj = new Date(order.refundDate);
      const formattedRefundDate = refundDateObj.toLocaleDateString(languageCodePrimary);
      const formattedRefundTime = refundDateObj.toLocaleTimeString(languageCodePrimary);
      page.drawText(
        `${translatedLabels['refundDate']}: ${formattedRefundDate} ${formattedRefundTime}`,
        { x: margin, y: currentY, size: 10, font: regularFont }
      );
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);
    }

    // Add gap after payment details
    currentY -= 10;

    // Item headers
    page.drawText(translatedLabels['quantity'], { x: margin, y: currentY, size: 10, font: boldFont });
    page.drawText(translatedLabels['item'], { x: margin + 30, y: currentY, size: 10, font: boldFont });
    page.drawText(translatedLabels['price'], { x: margin + 130, y: currentY, size: 10, font: boldFont });
    page.drawText(translatedLabels['vat'], { x: margin + 170, y: currentY, size: 10, font: boldFont });
    currentY -= 15;
    minY = Math.min(minY, currentY);

    const texts = order.texts;

    // Items
    for (const item of order.items) {
      const productName = texts[item.product.id];
      const sizeName = item.sizeId ? texts[item.product.sizes?.find(size => size.id === item.sizeId)?.id || ''] : '';
      const itemPriceString = OrderUtil.getOrderItemTotalAmount(item, order.currency).amountString;
      const productNameFull = `${productName} ${sizeName ? `(${sizeName})` : ''}`;

      const itemY = currentY;

      // Quantity, price, VAT percentage
      page.drawText(`${item.quantity}`, { x: margin, y: itemY, size: 10, font: regularFont });
      page.drawText(`${itemPriceString}`, { x: margin + 130, y: itemY, size: 10, font: regularFont });
      page.drawText(`${item.vatPercentage}%`, { x: margin + 170, y: itemY, size: 10, font: regularFont });

      // Product name with wrapping
      const productNameLines = this.wrapText(productNameFull, regularFont, 10, productNameColumnWidth);
      for (const line of productNameLines) {
        page.drawText(line, { x: margin + 30, y: currentY, size: 10, font: regularFont });
        currentY -= lineHeight;
        minY = Math.min(minY, currentY);
      }

      // Extras
      const extras = item.extraIds?.map(extraId =>
        item.product.extras.find(extra => extra.id === extraId)
      ).filter(Boolean);

      if (extras && extras.length > 0) {
        for (const extra of extras) {
          if (extra) {
            const extraAmountString = OrderUtil.getProductExtraAmount(extra, order.currency).amountString;
            const extraText = `+ ${texts[extra.id]} (${extraAmountString})`;

            const extraLines = this.wrapText(extraText, regularFont, 10, productNameColumnWidth);
            for (const line of extraLines) {
              page.drawText(line, { x: margin + 30, y: currentY, size: 10, font: regularFont });
              currentY -= lineHeight;
              minY = Math.min(minY, currentY);
            }
            currentY -= 2; // Extra spacing
          }
        }
      } else {
        currentY -= 12;
      }
      minY = Math.min(minY, currentY);
    }

    // Totals
    const {
      totalString,
      netString,
      vatString,
      tipString,
      discountString,
      serviceFeeString,
      deliveryFeeString,
      serviceFee,
      tip,
      discount,
    } = OrderUtil.getOrderAmount(order);

    currentY -= 10;
    minY = Math.min(minY, currentY);

    page.drawText(`${translatedLabels['netTotal']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
    page.drawText(`${netString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    page.drawText(`${translatedLabels['vat']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
    page.drawText(`${vatString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Discounts
    if (order.discountPercentage && discount > 0) {
      page.drawText(`${translatedLabels['discount']}: ${order.discountPercentage}%`, { x: margin, y: currentY, size: 10, font: regularFont });
      page.drawText(`-${discountString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);
    }

    // Additional charges and fees
    if (order.deliveryFee) {
      page.drawText(`${translatedLabels['deliveryFee']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
      page.drawText(`${deliveryFeeString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);
    }

    if (serviceFee > 0) {
      page.drawText(`${translatedLabels['serviceFee']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
      page.drawText(`${serviceFeeString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);
    }

    if (order.tipPercentage && tip > 0) {
      page.drawText(`${order.tipPercentage}% ${translatedLabels['tip']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
      page.drawText(`${tipString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);
    }

    // Total
    page.drawText(`${translatedLabels['total']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
    page.drawText(`${totalString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont });
    currentY -= lineHeight;
    minY = Math.min(minY, currentY);

    // Delivery information
    if (order.deliveryDetails && order.deliveryDetails.fullName.trim().length > 0) {
      const { fullName, phoneNumber: deliveryPhone, deliveryInstructions, address: deliveryAddress } = order.deliveryDetails;
      const deliveryFormattedAddress = `${deliveryAddress.street}, ${deliveryAddress.zip} ${deliveryAddress.city}`;

      currentY -= 20;
      minY = Math.min(minY, currentY);

      page.drawText(`${translatedLabels['deliveryInfo']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);

      page.drawText(`${translatedLabels['fullName']}: ${fullName}`, { x: margin, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);

      page.drawText(`${translatedLabels['phone']}: ${deliveryPhone}`, { x: margin, y: currentY, size: 10, font: regularFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);

      if (deliveryInstructions) {
        const deliveryInstructionsText = `${translatedLabels['deliveryInstructions']}: ${deliveryInstructions}`;
        const deliveryInstructionsLines = this.wrapText(deliveryInstructionsText, regularFont, 10, pageWidth - 2 * margin);
        for (const line of deliveryInstructionsLines) {
          page.drawText(line, { x: margin, y: currentY, size: 10, font: regularFont });
          currentY -= lineHeight;
          minY = Math.min(minY, currentY);
        }
        currentY -= 2; // Extra spacing
      }

      const addressText = `${translatedLabels['address']}: ${deliveryFormattedAddress}`;
      const addressLines = this.wrapText(addressText, regularFont, 10, pageWidth - 2 * margin);
      for (const line of addressLines) {
        page.drawText(line, { x: margin, y: currentY, size: 10, font: regularFont });
        currentY -= lineHeight;
        minY = Math.min(minY, currentY);
      }
      currentY -= 2; // Extra spacing
    }

    // Business receipt information
    if (order.businessDetails) {
      const { companyName, companyAddress, vatID: businessVatID } = order.businessDetails;

      currentY -= 20;
      minY = Math.min(minY, currentY);

      page.drawText(`${translatedLabels['businessDetails']}:`, { x: margin, y: currentY, size: 10, font: boldFont });
      currentY -= lineHeight;
      minY = Math.min(minY, currentY);

      const companyNameText = `${translatedLabels['companyName']}: ${companyName}`;
      const companyNameLines = this.wrapText(companyNameText, regularFont, 10, pageWidth - 2 * margin);
      for (const line of companyNameLines) {
        page.drawText(line, { x: margin, y: currentY, size: 10, font: regularFont });
        currentY -= lineHeight;
        minY = Math.min(minY, currentY);
      }
      currentY -= 2; // Extra spacing

      const companyAddressText = `${translatedLabels['companyAddress']}: ${companyAddress}`;
      const companyAddressLines = this.wrapText(companyAddressText, regularFont, 10, pageWidth - 2 * margin);
      for (const line of companyAddressLines) {
        page.drawText(line, { x: margin, y: currentY, size: 10, font: regularFont });
        currentY -= lineHeight;
        minY = Math.min(minY, currentY);
      }
      currentY -= 2; // Extra spacing

      if (businessVatID) {
        page.drawText(`${translatedLabels['vatID']}: ${businessVatID}`, { x: margin, y: currentY, size: 10, font: regularFont });
        currentY -= lineHeight;
        minY = Math.min(minY, currentY);
      }
    }

    // Compute content height (the main content height, not including the extra bottom row)
    const contentHeight = initialY - minY;

    if (skipSaving) {
      // First pass: Return content height without generating PDF bytes
      return { contentHeight };
    } else {
      // Add bottom IDs row (watermark style) without overlapping any other text.
      // This row will appear in the bottom margin.
      const smallFontSize = 6;
      // Construct one row containing all the IDs, separated by a vertical bar.
      const idsText = `${translatedLabels['businessID']}: ${customer.id} | ${translatedLabels['orderID']}: ${order.id} | ${translatedLabels['qrID'] || 'QR ID'}: ${order.qrCode.id || ''}`;
      const textWidth = regularFont.widthOfTextAtSize(idsText, smallFontSize);
      const xPos = (pageWidth - textWidth) / 2;
      const yPos = 5; // Footer anchored 5 points above the bottom
      page.drawText(idsText, {
        x: xPos,
        y: yPos,
        size: smallFontSize,
        font: regularFont,
        color: rgb(0.7, 0.7, 0.7),
      });

      // Second pass: Save and return the PDF bytes
      const pdfBytes = await pdfDoc.save();
      return { pdfBytes, contentHeight };
    }
  }

  // Helper function to wrap text
  static wrapText(text: string, font: PDFFont, fontSize: number, maxWidth: number): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let line = '';

    for (const word of words) {
      const testLine = line + (line ? ' ' : '') + word;
      const textWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (textWidth > maxWidth && line) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }

    if (line) {
      lines.push(line);
    }

    return lines;
  }
}
